import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { Person, DataUser } from './../../interface/person.interface';
import { SelectTipo, SelectGeneric } from './../../interface/selectTipo.interface';
import { EmitResultInterface } from '../../interface/emitResult.interface';

import { TipoSelectService } from './../../services/tipoSelect.service';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientInfoVO } from 'src/app/interface/client-info-vo.interface';
import { TipoDeUso } from 'src/app/interface/tipo-uso.interface';
import { ConsultaRFC } from 'src/app/interface/consultaRFC.interface';
import { ResponseString } from 'src/app/interface/response-string';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-form-invoice',
  templateUrl: './form-invoice.component.html',
  styleUrls: ['./form-invoice.component.css']
})
export class FormInvoiceComponent implements OnInit {

  color = 'warn';
  colorTexto = '';
  classBtn = '';
  autoRenew1 = new FormControl();
  changeIeps = false;
  nocolony = false;
  autoRenew2 = new FormControl();
  extranjero = false;
  validForeingData = false;
  disabledSelect = false;
  disabledState = false;
  tipouso: TipoDeUso;
  arrTipoUso: SelectTipo[] = [];
  arrRegimenFi: SelectTipo[] = [];

  // Formulario para recabar datos de Facturación
  formInvoice: FormGroup;

  dataInvoice: DataUser;
  consultaRFC: ConsultaRFC;

  estados: SelectTipo[] = [];
  municipios: SelectTipo[] = [];
  colonias: SelectTipo[] = [];
  residenciasFiscales: SelectTipo[] = [];
  arrPerson: SelectTipo[] = [];

  enableForm = false;

  btnAvailable = false;

  rfc_l = sessionStorage.getItem('rfc');
  type_p_l = sessionStorage.getItem('type_person');
  uso_c_l = sessionStorage.getItem('uso_cfdi');
  reg_fis = sessionStorage.getItem('reg_fiscal');
  billingId =  Number(sessionStorage.getItem('billingId'));
  xstore = sessionStorage.getItem('xstore');
  auxBillingId =  Number(sessionStorage.getItem('auxBillingId'));
  clientInfoVO: ClientInfoVO;


  key = '';
  state = '';

  resetForm = false;

  verFactura = '';

  // tableRfc
  constructor(private fb: FormBuilder,
              private tipoSelect: TipoSelectService,
              private spinnerService: NgxSpinnerService,
              private router: Router,
              config: NgbModalConfig,
              private modalService: NgbModal,
              private configService: AppConfigService) {
    this.colorTexto = this.configService.getConfig().colorTexto;
    this.classBtn = this.configService.getConfig().classBtn;
    config.backdrop = 'static';
    config.keyboard = false;
    this.formSinData();
    /*
    this.tipoSelect.getState().subscribe((data: SelectGeneric) => {
      this.estados = data.object;
    });
    */
    this.createFormInvoice();
  }

  ngOnInit() {
  }

  createFormInvoice() {
    console.log('ENTRO EN createFormInvoice' );
    const RFC = sessionStorage.getItem('rfc');
    this.clientInfoVO = {rfc: RFC};
    this.tipoSelect.getClient(this.clientInfoVO).subscribe((data: Person) => {
      console.log('DATA: ', data);
      if (!data.status) {
        swal('Error!', data.msg, 'error');
      } else {
        this.tipoSelect.getTipoPersona().subscribe((data: SelectGeneric) => {
          console.log('DATA: ', data);
          if (data !== null) {
            this.arrPerson = data.object;
          }
        });
        if (data.object.type !== null) {
          this.dataInvoice = data.object;
          console.log('DATO - ' + this.dataInvoice );

          
          this.getStateZipCode(this.dataInvoice.zipCode);
          this.getMunicipalityZipCode(this.dataInvoice.zipCode);
          this.getSuburbZipCode(this.dataInvoice.zipCode);

          console.log('paso consultas de estados antes de consulta persona' );

          

          //his.getRegimenFiscal(tPersona: FormGroup);
          this.formConData();
        } else {

          this.formSinData();
        }
      }
    });
  }
  mayus(e) {
    e.value = e.value.toUpperCase();
 }

  formConData() {
    this.disabledSelect = true;
    this.enableForm = true;
    this.btnAvailable = true;
    console.log('DATO - ' + this.dataInvoice.state );
    console.log('DATO - ' + this.dataInvoice.municipality );
    console.log('DATO - ' + this.dataInvoice.colony );

    this.state = this.dataInvoice.state;
   /* this.getMunicipality(this.dataInvoice.idState, this.dataInvoice.state); */
   /* this.getSuburb(this.dataInvoice.idMunicipality, this.dataInvoice.municipality); */
   // this.formInvoice.get('colony').setValue(this.dataInvoice.colony);
   // this.formInvoice.get('state').setValue(this.dataInvoice.state);
  //  this.formInvoice.get('municipality').setValue(this.dataInvoice.municipality);

  this.formInvoice.get('state').setValue(this.dataInvoice.state);

    if (this.dataInvoice.nacional === 'S' ||
         this.dataInvoice.nacional.trim() === '') {
      this.extranjero = false;
      this.dataInvoice.fiscalResidence = '';
      this.dataInvoice.numRegId = '';
    } else {
      this.extranjero = true;
      this.getResidenciaFisc();
      if (this.dataInvoice.fiscalResidence === '' || this.dataInvoice.fiscalResidence === null) {
        this.key = '';
        this.formInvoice.get('fiscalResidence').setValue(this.key);
      } else {
        this.key = this.dataInvoice.fiscalResidence.substr(0, 3);
        this.formInvoice.get('fiscalResidence').setValue(this.key);
      }
    }
    
    console.log('PASANDO');
    console.log("persona ", this.type_p_l);
    this.dataInvoice.iepsRequired = 'N';
    this.formInvoice = new FormGroup({
      iepsRequired: new FormControl( {value: this.dataInvoice.iepsRequired, disabled: true}),
      rfc: new FormControl( {value: this.rfc_l, disabled: true}),
      type: new FormControl( {value: this.type_p_l, disabled: true}),
      name: new FormControl( {value: this.dataInvoice.name, disabled: true}),
      paternalSurname: new FormControl( {value: this.dataInvoice.paternalSurname, disabled: true}),
      maternalSurname: new FormControl( {value: this.dataInvoice.maternalSurname, disabled: true}),
      email: new FormControl( {value: this.dataInvoice.email, disabled: true}),
      street: new FormControl( {value: this.dataInvoice.street, disabled: true}),
      outerNumber: new FormControl( {value: this.dataInvoice.outerNumber, disabled: true}),
      innerNumber: new FormControl( {value: this.dataInvoice.innerNumber, disabled: true}),
      zipCode: new FormControl( {value: this.dataInvoice.zipCode, disabled: true}),
      colony: new FormControl( {value: this.dataInvoice.colony, disabled: true}),
      colonyText: new FormControl( {value: this.dataInvoice.colony, disabled: true}),
      nocolony: new FormControl( {value: '', disabled: true}),
    //  idState: new FormControl( {value: this.dataInvoice.state, disabled: true}),
      state: new FormControl({value: this.dataInvoice.state, disabled: true}),
     // state: new FormControl( {value: this.dataInvoice.state, disabled: true}),
   /* idCity: new FormControl( {value: this.dataInvoice.idCity, disabled: true}),
      city: new FormControl( {value: this.dataInvoice.city, disabled: true}),
      idMunicipality: new FormControl( {value: this.dataInvoice.idMunicipality, disabled: true}), */
      municipality: new FormControl( {value: this.dataInvoice.municipality, disabled: true}),
      useCfdi: new FormControl( {value: this.uso_c_l, disabled: true}),
      regimenFiscal: new FormControl( {value: this.reg_fis, disabled: true}),
      nacional: new FormControl( {value: this.dataInvoice.nacional, disabled: true}),
      numRegId: new FormControl( {value: this.dataInvoice.numRegId, disabled: true}),
      fiscalResidence: new FormControl( {value: this.key, disabled: true}),
      solounico: new FormControl( {value: this.key, disabled: true}),
      idTransaction: new FormControl( {value: this.billingId, disabled: true})
    });

    //this.formInvoice.get('state').disable();
    //this.formInvoice.controls['state'].disable();
    // this.formInvoice.controls['state'].disable();
    // this.disabledState = true;
    /*
    this.formInvoice.controls['state'].disable();
    this.formInvoice.controls['municipality'].disable();
    */
  // this.formInvoice.disable();
    console.log('PASANDO 1 ' + this.extranjero);

   //this.formInvoice.disable();
  }

  formSinData() {

    console.log("entro en formSinData");
    this.tipoSelect.getRegimenFiscalList(this.type_p_l ).subscribe((data1: SelectGeneric) => {
      console.log('DATA: ', data1);
      this.arrRegimenFi = data1.object;
    });

    this.tipouso = {regFiscalId: this.reg_fis , typePerson: this.type_p_l};
    this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
      console.log('DATA: ', data2);
      this.arrTipoUso = data2.object;
    });
  
    this.getResidenciaFisc();
    this.disabledSelect = false;
    this.btnAvailable = false;
    this.formInvoice = this.fb.group({
      iepsRequired: ['N'],
      rfc: [this.rfc_l],
      type: [this.type_p_l],
      name: [''],
      paternalSurname: [''],
      maternalSurname: [''],
      email: [''],
      street: [''],
      outerNumber: [''],
      innerNumber: [''],
      zipCode: [''],
      colony: [''],
      colonyText: [''],
      nocolony: [''],
    /*  idState: [''],*/
      state: [''],
    /*idCity: [''],
      city: [''],
      idMunicipality: [''],  */
      municipality: [''],
      useCfdi: [this.uso_c_l],
      regimenFiscal: [this.reg_fis],
      nacional: ['S'],
      numRegId: [''],
      fiscalResidence: [''],
      solounico: [''],
      idTransaction: [this.billingId],
    });

    if (this.type_p_l=="M") {
    
      this.formInvoice.get('paternalSurname').disable();
      this.formInvoice.get('maternalSurname').disable();
      this.formInvoice.get('paternalSurname').setValue('');
      this.formInvoice.get('maternalSurname').setValue('');
    } else {
      this.formInvoice.get('paternalSurname').enable();
      this.formInvoice.get('maternalSurname').enable();
    }

    
  }

  getRegimenFiscal(tPersona: FormGroup) {
    this.type_p_l=tPersona.controls['type'].value;

    this.tipoSelect.getRegimenFiscalList(this.type_p_l).subscribe((data1: SelectGeneric) => {
      console.log('DATA: ', data1);
      this.arrRegimenFi = data1.object;
    });

    if (this.type_p_l=="M") {
    
      this.formInvoice.get('paternalSurname').disable();
      this.formInvoice.get('maternalSurname').disable();
      this.formInvoice.get('paternalSurname').setValue('');
      this.formInvoice.get('maternalSurname').setValue('');
    } else {
      this.formInvoice.get('paternalSurname').enable();
      this.formInvoice.get('maternalSurname').enable();

    }

  }

  //Se modifica getTipoUso para recibir regFiscalId y typePerson
  getTipoUso(tRegimen: FormGroup) {
    console.log('entro aqui ' , tRegimen.controls);
    
    console.log('entro  ' , tRegimen.controls['regimenFiscal'].value);
    this.tipouso = {regFiscalId: tRegimen.controls['regimenFiscal'].value , typePerson: this.type_p_l};
    this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
      console.log('DATA: ', data2);
      this.arrTipoUso = data2.object;
    });
  }

  getResidenciaFisc() {
    this.tipoSelect.getResidenciasFiscales().subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      if (!data.status) {
        swal('Error!', data.msg, 'error');
      } else {
        this.residenciasFiscales = data.object;
      }
    });
  }

  // Activa campos del formulario para poder editarlos
  enableField() {
    this.formInvoice.enable();
    console.log("érpsna enable ", this.type_p_l);

    if (this.type_p_l=="M") {
    
      this.formInvoice.get('paternalSurname').disable();
      this.formInvoice.get('maternalSurname').disable();
      this.formInvoice.get('paternalSurname').setValue('');
      this.formInvoice.get('maternalSurname').setValue('');
    } else {
      this.formInvoice.get('paternalSurname').enable();
      this.formInvoice.get('maternalSurname').enable();
    }

    
  }

  onChangeIEPS() {
    if (this.changeIeps) {
      this.changeIeps = false;
      this.formInvoice.get('iepsRequired').setValue('N');
    } else {
      this.changeIeps = true;
      this.formInvoice.get('iepsRequired').setValue('S');

    }
  }

  onChangeForeign() {
    if (this.extranjero) {
      this.dataInvoice.fiscalResidence = '';
      this.dataInvoice.numRegId = '';
      this.extranjero = false;
      this.formInvoice.get('nacional').setValue('S');
    } else {
      this.extranjero = true;
      this.formInvoice.get('nacional').setValue('N');
    }
  }

  getMunicipalityZipCode(zipCode) {
    this.tipoSelect.getMunicipality(zipCode).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.municipios = data.object;
    });
  }

  getMunicipality(id, value) {
    this.formInvoice.get('state').setValue(value);
    if(id=="0"){
     
      console.log("value ", value);
      id=value;
    }
    this.tipoSelect.getMunicipality(id).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.municipios = data.object;
    });
  }

  getStateZipCode(zipCode) {
  this.tipoSelect.getStateZipCode(zipCode).subscribe((data: SelectGeneric) => {
    this.estados = data.object;
  });
}

  getSuburbZipCode(zipCode) {
    this.tipoSelect.getSuburb(zipCode).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.colonias = data.object;
    });
  }

  getSuburb(id, value) {
    this.formInvoice.get('municipality').setValue(value);
    if(id=="0"){
     
      console.log("getSuburb value ", value);
      id=value;
    }

    this.tipoSelect.getSuburb(id).subscribe((data: SelectGeneric) => {
      console.log('DATA: ', data);
      this.colonias = data.object;
    });
  }



  openBottomSheet(formInvoice: FormGroup, content): void {
    console.log('iepsRequired: ', this.formInvoice.controls['iepsRequired'].value);
    this.spinnerService.show();
    this.dataInvoice = formInvoice.value;
    if (this.nocolony) {
      this.dataInvoice.colony=  this.formInvoice.get('colonyText').value;
      console.log('colonyText: ', this.dataInvoice.colony);

    } else {

      this.dataInvoice.colony=  this.formInvoice.get('colony').value;
      console.log('colony: ', this.dataInvoice.colony);
      
      
    }
   
    this.dataInvoice.idTransactionAux = this.auxBillingId.toString();
    console.log('DataInvoice: ', this.dataInvoice);
    this.dataInvoice.email = this.dataInvoice.email.trim().toLowerCase(); // Se envía en minúscula el correo
    this.dataInvoice.xstore = this.xstore;
    this.formInvoice.enable();

    //Arma objeto para validar RFC
    if (this.dataInvoice.type === 'F') {
          this.consultaRFC = {businessName:this.dataInvoice.name+" "+this.dataInvoice.paternalSurname+" "+this.dataInvoice.maternalSurname, postalCode:this.dataInvoice.zipCode, regimenFiscal:this.dataInvoice.regimenFiscal, rfc:this.dataInvoice.rfc};
          this.consultaRFC.businessName = this.consultaRFC.businessName.trim();
    }else{
    this.consultaRFC = {businessName:this.dataInvoice.name, postalCode:this.dataInvoice.zipCode, regimenFiscal:this.dataInvoice.regimenFiscal, rfc:this.dataInvoice.rfc};
    this.consultaRFC.businessName = this.consultaRFC.businessName.trim();
    }
    
    if(formInvoice.valid){
      this.validateForeignData();
      if(this.validForeingData){

        if(this.dataInvoice.rfc=='XAXX010101000'){
          this.tipoSelect.sendInvoice(this.dataInvoice).subscribe((data: EmitResultInterface) => {
            console.log('DATA: ', data);
            if (data.object.errorMsg === '') {
              this.spinnerService.hide();
              sessionStorage.clear();
              sessionStorage.setItem('consultRfc', this.rfc_l);
              this.verFactura = data.object.newItu;
              this.modalService.open(content);
            } else {
              this.spinnerService.hide();
              swal('', data.object.errorMsg, 'error');
            }
          }, err => {
            console.error(err);
            this.spinnerService.hide();
            this.resetForm = true;
            swal('Error', 'Error al conectar con el Servidor', 'error');
          });

        }else{
          this.tipoSelect.validaRFC(this.consultaRFC).subscribe((data: ResponseString) => {
            console.log('DATA: ', data);
            
            if (data.status) {
                this.tipoSelect.sendInvoice(this.dataInvoice).subscribe((data: EmitResultInterface) => {
              console.log('DATA: ', data);
              if (data.object.errorMsg === '') {
                this.spinnerService.hide();
                sessionStorage.clear();
                sessionStorage.setItem('consultRfc', this.rfc_l);
                this.verFactura = data.object.newItu;
                this.modalService.open(content);
              } else {
                this.spinnerService.hide();
                swal('', data.object.errorMsg, 'error');
              }
            }, err => {
              console.error(err);
              this.spinnerService.hide();
              this.resetForm = true;
              swal('Error', 'Error al conectar con el Servidor', 'error');
            });
            } else {
              this.spinnerService.hide();
              swal('', data.msg, 'error');
            }
          }, err => {
            console.error(err);
            this.spinnerService.hide();
            swal('Error!', 'Error al conectar con el Servidor', 'error');
          });

        }
      
		    
        
      }
    }else{
      this.spinnerService.hide();
      swal('Adevertencia', 'Por favor llene todos los campos obligatorios remarcados con rojo', 'warning');
    }
  }

  verFact() {
    this.modalService.dismissAll();
    this.router.navigate(['/home']);
  }

  getList( ) {
    const code = this.formInvoice.get('zipCode').value;
    // console.log(code.length);
    if (code.length === 5) {
      console.log(' YA ESTA ');
      this.getStateZipCode(code);
      this.getMunicipalityZipCode(code);
      this.getSuburbZipCode(code);

    }
  }
  //Habilita colonoa en texto

  completeItem(){

    if (this.nocolony) {
      this.nocolony = false;
      this.formInvoice.get('colony').enable();
      this.formInvoice.get('colonyText').disable();
    } else {
      this.nocolony = true;
      this.formInvoice.get('colony').disable();
      this.formInvoice.get('colonyText').enable();

    }

    

  }

  // Reinicia el proceso de Genera Factura
  reiniciarProceso() {
    //this.router.navigate(['/home']);
    //sessionStorage.clear();


  }

  validateForeignData(){
    console.log(this.extranjero);
    console.log(this.dataInvoice.fiscalResidence.trim());
    console.log(this.dataInvoice.numRegId.trim());
    if(this.extranjero){
      if(this.dataInvoice.fiscalResidence.trim() === '' || 
      this.dataInvoice.numRegId.trim() === ''){
        this.validForeingData = false;
        swal('Advertencia', 'Sí la opción de extrajero está habilitada, debe llenar los campos Número de Registro y Residencia fiscal', 'warning');
      }else{
        this.validForeingData = true;
      }
    }else{
      this.validForeingData = true;
      this.dataInvoice.fiscalResidence = '';
      this.dataInvoice.numRegId = '';
    }
  }
}
