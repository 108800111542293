import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService{
    private appConfig;

    constructor(private httpClient: HttpClient){}

    loadAppConfig(){
        var file = '';
        console.log("hostname ", window.location.hostname);
        console.log("href ", window.location.href);
        if(window.location.hostname == 'localhost'){
            file = 'assets/config/local.json?v=1';
          }else if(window.location.hostname == 'facturacion.officedepot.com.mx'){//Comentar para dev
            file = 'assets/config/officeProdActual.json?v=1';
          }else if(window.location.hostname == 'facturacion.radioshack.com.mx'){
            file = 'assets/config/radioProdActual.json?v=1';
          }else if(window.location.hostname == 'facturacion.tiendas-hes.com.mx') {
            file = 'assets/config/huaweiProdActual.json?v=1';
          }else if(window.location.hostname == 'facturacion.tiendas-mi.com.mx'){
            file = 'assets/config/xiaomiProdActual.json?v=1';
          }else if(window.location.hostname == '9.9.10.59'){
            file = 'assets/config/officeProdInt.json?v=1';
          } else if(window.location.href.includes("billingUatOD")) {
            file = 'assets/config/officeDev.json?v=1';
          } else if(window.location.href.includes("billingUatRS")) {
            file = 'assets/config/radioDev.json?v=1';
          } else if(window.location.href.includes("billingUatHuawei")) {
            file = 'assets/config/huaweiDev.json?v=1';
          } else if(window.location.href.includes("billingUatMI")) {
            file = 'assets/config/xiaomiDev.json?v=1';
          }else if(window.location.href.includes("OD-preprod")) {
            file = 'assets/config/officePreProd.json?v=1';
          }else if(window.location.href.includes("HU-preprod")) {
            file = 'assets/config/huaweiPreProd.json?v=1';
          }else if(window.location.href.includes("RS-preprod")) {
            file = 'assets/config/radioPreProd.json?v=1';
          }else if(window.location.href.includes("MI-preprod")) {
            file = 'assets/config/xiaomiPreProd.json?v=1';
          }
        return this.httpClient.get(file)
            .toPromise()
            .then(data => {
                this.appConfig = data;
            });
    }

    getConfig(){
        return this.appConfig;
    }
}