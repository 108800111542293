import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataUser } from './../interface/person.interface';
import { AppConfigService } from '../app-config.service';
import { ConsultaRFC } from '../interface/consultaRFC.interface';


@Injectable({
    providedIn: 'root'
})

export class TipoSelectService {

    private URL = '';

    private URLFORM = '';

    private URL_REINVOICE = '';

    private URL_CONSULTA='';

    constructor(private http: HttpClient, private configService: AppConfigService) {
        this.URL = this.configService.getConfig().urlServiceCatalogos;
        this.URLFORM = this.configService.getConfig().urlServiceInvoice;
        this.URL_REINVOICE = this.configService.getConfig().urlServiceReinvoice;
        this.URL_CONSULTA =  this.configService.getConfig().urlServiceConsulta;
    }

    getTipoPersona() {
        console.log('new object in request Catalogs /getPersonTypeList -> ');
        return this.http.post(`${this.URL}/getPersonTypeList`, '');
    }

    getTipoUsoF() {
        console.log('new object in request Catalogs /getUsageTypePhysicalList -> ');
        return this.http.post(`${this.URL}/getUsageTypePhysicalList`, '');
    }

    getTipoUsoM() {
        console.log('new object in request Catalogs /getUsageTypeMoralList -> ');
        return this.http.post(`${this.URL}/getUsageTypeMoralList`, '');
    }

    //Se agrega getRegimenFiscalList  para obtener régimen fiscal para tipos de personas

    getRegimenFiscalList(person) {
        console.log('new object in request Catalogs /getRegimenFiscalList -> ', person);
        return this.http.post(`${this.URL}/getRegimenFiscalList`, person);
    }

    //Se agrega getUsageTypeList  para uso de CFDI, acorde a tipo persona y régimen fiscal

    getUsageTypeList (datosReg) {
        console.log('new object in request Catalogs /getUsageTypeList -> ', datosReg);
        return this.http.post(`${this.URL}/getUsageTypeList`, datosReg);
    }

    // Combos para Estado, Municipio y Provincia

    getRfcStatus(rfc){
        console.log('new object in request Invoice /getRfcStatus -> ', rfc);
        return this.http.post(`${this.URLFORM}/getRfcStatus`, rfc);
    }

    getClient(rfc) {
        console.log('new object in request Invoice /getClientData -> ', rfc);
        return this.http.post(`${this.URLFORM}/getClientData`, rfc);
    }

    getState() {
        console.log('new object in request Catalogs /getStateList -> ');
       return this.http.post(`${this.URL}/getStateList`, '');
    }

    getStateZipCode(id) {
        console.log('new object in request Catalogs /getStateList -> ', id);
        return this.http.post(`${this.URL}/getStateList`, id);
     }

    getMunicipality(id) {
        console.log('new object in request Catalogs /getMunicipalityList -> ', id);
        return this.http.post(`${this.URL}/getMunicipalityList`, id);
    }

    getSuburb(id) {
        console.log('new object in request Catalogs /getSuburbList -> ', id);
        return this.http.post(`${this.URL}/getSuburbList`, id);
    }

    // Emitir Invoice
    sendInvoice(obj: DataUser) {
        console.log(this.URLFORM);
        console.log('new object in request Invoice /emitInvoice -> ', obj);
        return this.http.post(`${this.URLFORM}/emitInvoice`, obj);
    }

    getResidenciasFiscales() {
        console.log('new object in request Catalogs /getCountriesList -> ');
        return this.http.post(`${this.URL}/getCountriesList`, '');
    }

    sendReInvoice(obj: DataUser) {
        console.log(this.URL_REINVOICE);
        console.log('new object in request Reinvoice /emitInvoice -> ', obj);
        return this.http.post(`${this.URL_REINVOICE}/emitInvoice`, obj);
    }
    validaRFC(obj: ConsultaRFC) {
        console.log('new object in request Consult /validRfc -> ', obj);
        return this.http.post(`${this.URL_CONSULTA}/validRfc`, obj);
    }

}
