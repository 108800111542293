import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SelectGeneric, SelectTipo } from './../../interface/selectTipo.interface';
import { ResponseString } from './../../interface/response-string';

import { TipoSelectService } from 'src/app/services/tipoSelect.service';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { DataUser } from 'src/app/interface/person.interface';
import { ClientInfoVO } from 'src/app/interface/client-info-vo.interface';
import { TipoDeUso } from 'src/app/interface/tipo-uso.interface';
import { AppConfigService } from '../../app-config.service';

@Component({
  selector: 'app-ingresa-rfc',
  templateUrl: './ingresa-rfc.component.html',
  styleUrls: ['./ingresa-rfc.component.css']
})
export class IngresaRfcComponent implements OnInit {
  arrPerson: SelectTipo[] = [];
  arrTipoUso: SelectTipo[] = [];
  arrRegimenFi: SelectTipo[] = [];

  colorTexto = '';
  classBtn = '';
  

  // Formulario para ingreso de RFC, Tipo Persona y Tipo Uso
  myForm: FormGroup;
  rfcUser: ClientInfoVO;
  tipouso: TipoDeUso;
  persona: string;

  constructor(private tipoSelect: TipoSelectService,
              private fb: FormBuilder,
              private router: Router,
              private spinnerService: NgxSpinnerService,
              private configService: AppConfigService) {
    this.colorTexto = this.configService.getConfig().colorTexto;
    this.classBtn = this.configService.getConfig().classBtn;
    this.createFormRFC();
    this.tipoSelect.getTipoPersona().subscribe( (data: SelectGeneric) => {
      console.log('DATA: ', data);
      if (data !== null) {
        this.arrPerson = data.object;
      }
    });
  }

  createFormRFC() {
    this.myForm = this.fb.group({
      'rfc': ['',
              [
                Validators.minLength(12),
                Validators.maxLength(13),
                Validators.pattern('[A-Za-zñÑ\x26]{3,4}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}'),
                Validators.required
              ]
          ],
      'tipo_persona': ['', Validators.required],
      'regimen_fiscal': ['', Validators.required],
      'tipo_uso': ['', Validators.required]
    });
  }



  ngOnInit() { }



  sendData(data: FormGroup) {
    if (this.validateRFC()) {
      this.rfcUser = {rfc: this.myForm.controls.rfc.value.toUpperCase()};
      this.tipoSelect.getRfcStatus(this.rfcUser).subscribe((data: any) =>{
        console.log('DATA: ', data);
        if(data !== null){
          if(data['object'] !== null){
            var resp = data['object'];
            if(resp == ''){
              this.spinnerService.show();
              sessionStorage.setItem('rfc', this.myForm.controls.rfc.value.toUpperCase());
              sessionStorage.setItem('type_person', this.myForm.controls.tipo_persona.value);
              sessionStorage.setItem('reg_fiscal', this.myForm.controls.regimen_fiscal.value);
              sessionStorage.setItem('uso_cfdi', this.myForm.controls.tipo_uso.value);
              this.spinnerService.hide();
              this.router.navigate(['/dataInvoice']);
            }else{
              swal('Error', resp, 'error');
            }
          }else{
            swal('Error', 'No se pudo comprobar estatus del rfc.', 'error');
          }
        }else{
          swal('Error', 'Servicios no disponible.', 'error');
        }
      }, err => {
        swal('Error', 'Error al conectar con el Servidor', 'error');
      });
        
      
    }
  }

  /*getTipoUso(tPersona: FormGroup) {
    if (tPersona.controls['tipo_persona'].value === 'F') {
      this.validateRfcFisica();
      this.tipoSelect.getTipoUsoF().subscribe((data1: SelectGeneric) => {
        this.arrTipoUso = data1.object;
      });
    } else {
      this.validateRfcMoral();
      this.tipoSelect.getTipoUsoM().subscribe((data2: SelectGeneric) => {
        this.arrTipoUso = data2.object;
      });
    }
  }*/

  
  getRegimenFiscal(tPersona: FormGroup) {
    this.persona=tPersona.controls['tipo_persona'].value;
    if (tPersona.controls['tipo_persona'].value === 'F') {
      this.validateRfcFisica();
      
    } else {
      this.validateRfcMoral();
    }

    this.tipoSelect.getRegimenFiscalList(tPersona.controls['tipo_persona'].value).subscribe((data1: SelectGeneric) => {
      console.log('DATA: ', data1);
      this.arrRegimenFi = data1.object;
    });
  }
  //Se modifica getTipoUso para recibir regFiscalId y typePerson
  getTipoUso(tRegimen: FormGroup) {
    console.log('entro aqui ' , tRegimen.controls);
    
    console.log('entro  ' , tRegimen.controls['regimen_fiscal'].value);
    this.tipouso = {regFiscalId: tRegimen.controls['regimen_fiscal'].value , typePerson: this.persona};
    this.tipoSelect.getUsageTypeList(this.tipouso).subscribe((data2: SelectGeneric) => {
      console.log('DATA: ', data2);
      this.arrTipoUso = data2.object;
    });
  }

  private validateRfcFisica() {
    const patt = new RegExp('^[A-Za-zñÑ\x26]{4}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}$');
    console.log(patt.test(this.myForm.controls['rfc'].value));
    if (!patt.test(this.myForm.controls['rfc'].value)) {
      this.sendMessagePattern();
      return false;
    } else {
      this.sendMessagePatternOK();
      return true;
    }
  }

  private validateRfcMoral() {
    const patt = new RegExp('^[A-Za-zñÑ\x26]{3}[0-9]{2}[01][0-9][0-3][0-9][!-}ñÑ]{3}$');
    console.log(patt.test(this.myForm.controls['rfc'].value));
    if (!patt.test(this.myForm.controls['rfc'].value)) {
      this.sendMessagePattern();
      return false;
    } else {
      this.sendMessagePatternOK();
      return true;
    }
  }

  private sendMessagePattern() {
    const inputTech: any = document.querySelector('input[name=rfc]');
    inputTech.focus();
    this.myForm.controls['rfc'].setErrors({ 'touched': true });
    this.myForm.controls['rfc'].setErrors({ 'pattern': true });
    const inputTech3: any = document.querySelector('input[name=rfc]');
    inputTech3.focus();
  }

  private sendMessagePatternOK() {
    this.myForm.controls['rfc'].setErrors(null);
  }

  private validateRFC(): boolean {
    if (this.myForm.value.tipo_persona === 'F') {
      return this.validateRfcFisica();
    } else if (this.myForm.value.tipo_persona === 'M') {
      return this.validateRfcMoral();
    }
  }

  // Reinicia el proceso de Genera Factura
  reiniciarProceso() {
    this.router.navigate(['/home']);
    sessionStorage.clear();
  }

}
